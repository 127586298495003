import React from 'react'
import banner from '../../assets/images/yotube banner.png'
export const About = () => {

  return (
    <div>
      <section>
        <div className="hero">
          <div className="container">
            <div className="bunner">
              <img src={banner} className="bunner-img" alt="bunner-img"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
