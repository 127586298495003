
import { Route, Routes } from 'react-router-dom';
import './assets/style/scss/main.scss';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { About } from './pages/about/About';
import { Home } from './pages/home/Home';

function App() {
  return (
    <>
      <Header/>
      <main className='site-main'>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/posts" element={"posts"} />
      </Routes>
      </main>
     <Footer/>
    </>
  );
}

export default App;
