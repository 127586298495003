import React from 'react'

export const CursorIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_0_183"  maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
<path d="M15.5 2.58331V7.74998" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.2083 14.2083L27.125 16.7916L23.25 19.375L27.125 23.25L23.25 27.125L19.375 23.25L16.7916 27.125L14.2083 14.2083Z" fill="white" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24.6334 6.36658L20.9799 10.0201M6.3666 24.6333L10.0201 20.9798M2.58331 15.5H7.74998M6.3666 6.36658L10.0201 10.0201" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
</mask>
<g mask="url(#mask0_0_183)">
<path d="M0 0H31V31H0V0Z" fill="white"/>
</g>
</svg>

  )
}
