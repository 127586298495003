import React, { useState } from 'react';
import MeImg from "../../assets/images/Me.png";
import Github from "../../assets/images/github.png";
import Telegram from "../../assets/images/telegram.png";
import Instagram from "../../assets/images/instagrams.png";
import Facebook from "../../assets/images/facebook.png";
import Youtube from "../../assets/images/youtube.png";
export const MobileHome = () => {
  const [menuActive, setMenuActive] = useState(false);
  function addMenu() {
    setMenuActive(!menuActive)
  }
    const listActive = menuActive ? 'list-active logo-list' : 'logo-list';
  return (
    <div className='mbl-home'>
      <section className='mbl-hero'>
        <div className="container">
          <div className="hero__wrapper">
            <div className="hero__me-content">
              <div className="hero__img">
                <img src={MeImg} width="258" height="260" alt="img me" />
              </div>
              <div className="hero__content">
                <p className="me-name">Hello! I Am <span className='text-color'>Bunyod Ag’zamxo’jayev</span></p>
                <p className='text'>A Programmer who</p>
                <h1 className='title' >
                  Judges a book
                  by its <span className='text-color'>cover</span>...
                </h1>
                <p className='dizayn-text'>If the design does not impress you what else can?</p>
              </div>
            </div>
            <p className='content-title'>I am a Frontend developer</p>
            <p className='content-instagram'>I currently advertise my brand on <a href='https://www.instagram.com/_.bunyod._.boss._' target="blank" className='text-color instagram' > Instagram</a></p>
            <p className='content-text'>Frontend developer who studied at NajotTalim, has been working in the field for 2+ years.
              I create user-friendly and modern sites for everyone between user needs and business goals.</p>
          </div>
        </div>
      </section>
      <section className='mbl-footer'>
        <div className="menyu-group">
           <button className="sircle" onClick={addMenu}></button>
          <ul  className={listActive}>
            <li className='logo-item'>
              <a href="https://github.com/Bunyood" target="blank">
                <img width="30" height="30"  src={Github} alt="Github" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://t.me/BunyodBoss" target="blank">
                <img width="30" height="30"  src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://www.youtube.com/channel/UCfDt3SU7wg_hahE_ajOAmFQ" target="blank">
                <img width="30" height="30"  src={Youtube} alt="Youtube" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://www.instagram.com/_.bunyod._.boss._" target="blank">
                <img width="30" height="30"  src={Instagram} alt="Instagram" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://www.facebook.com/profile.php?id=100083037582851" target="blank">
                <img width="30" height="30"  src={Facebook} alt="Facebook" />
              </a>
            </li>
          </ul>
        </div>
        <svg className='btn-bottom' width="162" height="52" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M143.138 8.3211e-05C119.5 3.01588e-05 126 47 77.0007 48.0001C27.5015 48.0001 30.3211 -0.0593615 7.06433 5.50478e-05C-20.5205 0.0705288 39.1931 9.36716e-05 74.1633 9.36716e-05C109.134 9.36716e-05 176.074 0.000157133 143.138 8.3211e-05Z" fill="#251C31"/>
        </svg>

      </section>
    </div>
  )
}
