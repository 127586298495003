import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from "../../assets/images/Logo.png"
export const Header = () => {
  return (
    <header className='site-hieadr'>
      <div className="scroll-top">
        <div className='scroll'></div>
      </div>
      <div className="container">
        <div className="haeader-wrapper">
          <Link to="/">
            <img src={Logo} width="283" height="95" alt="ZamonaviyBrand" />
          </Link>
          <nav className='nav'>
            <ul className='nav__list'>
              <li className='nav__item'>
                <NavLink className={({ isActive }) =>
                  isActive ? "nav__link nav__link--active" : "nav__link"
                } to="/">Home</NavLink>
              </li>
              <li className='nav__item'>
                <NavLink className={({ isActive }) =>
                  isActive ? "nav__link nav__link--active" : "nav__link"
                } to="/about">About</NavLink>
              </li>
              <li className='nav__item'>
                <NavLink className={({ isActive }) =>
                  isActive ? "nav__link nav__link--active" : "nav__link"
                } to="/Posts">Posts</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
