import React from 'react'
import { Button } from '../ui/button/Button'

export const CardItem = ({img,title,text}) => {
  return (
    <li className='card'>
    <div className='card__img'>
      <img src={img} width="230" height="143"  alt="portfolio" />
    </div>
    <div className='card__content'>
      <h3 className='card__title'>{title}</h3>
      <p className='card__text'>{text}
      </p>
      <Button className="card__btn" type="button">LEARN MORE
      </Button>
    </div>
  </li>
  )
}
