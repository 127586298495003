import React from 'react'
import porfolio1 from "../../assets/images/Screenshot_4.png"
import porfolio2 from "../../assets/images/Screenshot_5.png"
import porfolio3 from "../../assets/images/Screenshot_6.png"
import porfolio4 from "../../assets/images/Screenshot_7.png"
import { CardItem } from './CardItem'

export const CardList = () => {
  const card = [
    {
      id: '1',
      title: 'CIB on the Mobile',
      text: "Take your client onboard seamlessly by our amazing tool of digital onboard process.",
      img:porfolio1
    },
    {
      id: '2',
      title: 'CIB on the Mobile',
      text: "Take your client onboard seamlessly by our amazing tool of digital onboard process.",
      img:porfolio2
    },
    {
      id: '3',
      title: 'CIB on the Mobile',
      text: "Take your client onboard seamlessly by our amazing tool of digital onboard process.",
      img:porfolio3
    },
    {
      id: '4',
      title: 'CIB on the Mobile',
      text: "Take your client onboard seamlessly by our amazing tool of digital onboard process.",
      img:porfolio4
    }
  ]
  return (
    <ul className='card-list'>
      {card.map((item, index) => {
        return <CardItem key={index} img={item.img} title={item.title} text={item.text} />
      })
      }
      </ul>
  )
}
