import React from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../assets/images/Logo.png"
import Github from "../../assets/images/github.png"
import Telegram from "../../assets/images/telegram.png"
import Instagram from "../../assets/images/instagrams.png"
import Facebook from "../../assets/images/facebook.png"
export const Footer = () => {
  return (
    <footer className='site-footer'>
      <div className="container">
        <div className="footer__wrapper">
          <Link to="/">
            <img src={Logo} width="283" height="95" alt="logo" />
          </Link>
          <div className="verrsion">
          <ul className='logo-list'>
            <li className='logo-item'>
              <a href="https://github.com/Bunyood" target="blank">
                <img width="30" height="30"  src={Github} alt="Github" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://t.me/BunyodBoss" target="blank">
                <img width="30" height="30"  src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://www.instagram.com/_.bunyod._.boss._" target="blank">
                <img width="30" height="30"  src={Instagram} alt="Instagram" />
              </a>
            </li>
            <li className='logo-item'>
              <a href="https://www.facebook.com/profile.php?id=100083037582851" target="blank">
                <img width="30" height="30"  src={Facebook} alt="Facebook" />
              </a>
            </li>
          </ul>
          <p className='version-text'>
                  v.1.0.0
                </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
