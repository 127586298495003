import React from "react";

export const ImgCurrently = () => {
  return <>
    <div className="design-img">
    <span className="center"></span>
    <span className="center-shedov"></span>
  <div className="seircle-group" >
    <span className="seircle-1"> <span className="seircle-child"></span></span>
    <span className="seircle-1 oni"> <span className="seircle-child"></span></span>
    <span className="seircle-1 runi"> <span className="seircle-child"></span></span>
    </div>;
    </div>
    </>
};
