import React from 'react'
import MeImg from "../../assets/images/Me.png"
import Taqsim from "../../assets/images/Screenshot_2.png"
import Kafolat from "../../assets/images/Screenshot_2 1.png"
import GeoSolution from "../../assets/images/Screenshot_8.png"
import Enegimarcet from "../../assets/images/Screenshot_3.png"
import { CardList } from '../../components/card/CardList'
import { CursorIcon } from '../../assets/icons/icons'
import { MobileHome } from '../mobile/MobileHome'
import { ImgCurrently } from '../../components/animation/ImgCurrently'
export const Home = () => {
  return (
    <>
      <div className='des-home'>
      <section className='hero'>
        <div className="container">
          <div className="hero__wrapper">
            <div className="hero__me-content">
              <div className="hero__img">
                <img src={MeImg} width="258" height="260" alt="img me" />
              </div>
              <div className="hero__content">
                <p className="me-name">Hello! I Am <span className='text-color'>Bunyod Ag’zamxo’jayev</span></p>
                <p className='text'>A Programmer who</p>
                <h1 className='title' >
                  Judges a book
                  by its <span className='text-color'>cover</span>...
                </h1>
                <p className='dizayn-text'>If the design does not impress you what else can?</p>
              </div>
            </div>
            <p className='content-title'>I am a Frontend developer</p>
            <p className='content-instagram'>I currently advertise my brand on <a href='https://www.instagram.com/_.bunyod._.boss._' target="blank" className='text-color instagram' > Instagram</a></p>
            <p className='content-text'>Frontend developer who studied at NajotTalim, has been working in the field for 2+ years.
              I create user-friendly and modern sites for everyone between user needs and business goals.</p>
          </div>
        </div>
      </section>
      <section className='work'>
        <div className="container">
          <div className="work-box">
            <h2 className='work-box__title'>Work Experience</h2>
            <CardList />
          </div>
        </div>
      </section>
      <section className='currently'>
        <div className="container">
          <div className="currently__wrapper">
            <h2 className='currently__title'>Currently, I work with a
              <span className='text-color'> large team
            </span> <br/>
              I appreciate improving people's lives through a convenient site.</h2>
              <div className="currently__img">
                <ImgCurrently/>
              {/* <img className='brand-img' src={currently}  alt="img me" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className='project'>
        <div className="container">
          <div className="project__wrapper">
            <ul>
              <li className='project__card'>
                <div className="project__conent">
                  <p className='project__title'>Featured  Project</p>
                  <h3 className='project__name'>Taqsim Project</h3>
                  <div className="project__box">
                    <p className='project__text'>We are a trading platform where you can reach common values and find a wide range of products that will not leave you indifferent.</p>
                  </div>
                  <a href='https://taqsim.uz/' target='blank' className="project__icon"><CursorIcon/> <CursorIcon/></a>
                    </div>
                <div className="project__img-box">
                  <img className='project__img'  width="561" height="317" src={Taqsim} alt="taqsim project" />
                    </div>
              </li>
              <li className='project__card'>
                <div className="project__conent">
                  <p className='project__title'>Featured  Project</p>
                  <h3 className='project__name'>Energymarket Project</h3>
                  <div className="project__box">
                    <p className='project__text'>This platform is designed to provide population compensation and research and purchase equipment for renewable energy sources.</p>
                  </div>
                  <a href='https://energymarket.uz/'  target='blank'  className="project__icon"><CursorIcon/> <CursorIcon/></a>
                    </div>
                <div className="project__img-box">
                  <img className='project__img' width="561" height="317" src={Enegimarcet} alt="taqsim project" />
                    </div>
              </li>
              <li className='project__card'>
                <div className="project__conent">
                  <p className='project__title'>Featured  Project</p>
                  <h3 className='project__name'>Kafolat Project</h3>
                  <div className="project__box">
                    <p className='project__text'>The joint-stock company "Guarantee Insurance Company" was established on March 14, 1997, and operates on the basis of the Decree of the President of the Republic of Uzbekistan dated 09.10.2017. PQ - 3317 On measures to improve the activities of "Guarantee Insurance Company" OJSC.</p>
                  </div>
                  <a href='https://kafolat.uz' target='blank' className="project__icon"><CursorIcon/> <CursorIcon/></a>
                </div>
                <div className="project__img-box">
                  <img className='project__img'  width="561" height="317" src={Kafolat} alt="taqsim project" />
                </div>
              </li>
              <li className='project__card'>
                <div className="project__conent">
                  <p className='project__title'>Featured  Project</p>
                  <h3 className='project__name'>GeoSolutions Project</h3>
                  <div className="project__box">
                    <p className='project__text'>We offer you comprehensive solutions, including searching for locations throughout Uzbekistan, selecting the most convenient bus stations and much more. By constantly improving our services, we develop new effective solutions that meet your needs.
                  </p>
                  </div>
                  <a href='https://geosolutions.uz/'  target='blank'  className="project__icon"><CursorIcon/> <CursorIcon/></a>
                </div>
                <div className="project__img-box">
                  <img className='project__img' width="561" height="317" src={GeoSolution} alt="GeoSolution project" />
                    </div>
              </li>
            </ul>
            <div className="me__content">
              <h2 className='me__content--title'>Contact</h2>
                <p className='me__content--text'>I'm currently building mutually beneficial sites that value improving people's lives.</p>
            </div>
          </div>
        </div>
      </section>
      </div>
      <MobileHome/>
    </>
  )
}
